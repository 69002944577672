<template>
  <div class="wrapper services-page">
    <app-header router-link="HotelPage" />
    <img :src="categoryDetails.logoKey" alt="" class="services-page__image" />
    <service-skeleton v-if="isLoading" />
    <div v-else class="services-page__block">
      <div class="services-page__logo">
        <img src="@/assets/images/palace.png" alt="" />
      </div>
      <span
        :class="[
          'services-page__title',
          'element_padding-bottom',
          {
            'services-page__title_margin':
              categoryDetails?.subcategories?.length,
          },
        ]"
        >{{ categoryDetails?.translations?.ru_RU?.name }}</span
      >
      <hotel-navbar
        v-if="categoryDetails?.subcategories?.length"
        :is-animation="isAnimation"
        :is-open-modal="isModalVisible"
        class="services-page__navbar"
        :categories="categoryDetails?.subcategories"
        @animate="changeAnimation"
      />
      <div
        v-if="categoryDetails?.services?.length"
        class="services-page__main main"
      >
        <service-list
          :id="slug"
          :cards="categoryDetails?.services"
          @book="openBookModal"
        />
      </div>
      <div
        v-if="categoryDetails?.subcategories?.length"
        class="services-page__main main"
      >
        <service-list
          v-for="subcategory in categoryDetails?.subcategories"
          :id="subcategory.slug"
          :key="subcategory.slug"
          :cards="subcategory?.services"
          :title="subcategory.translations.ru_RU.name"
          @book="openBookModal"
        />
      </div>
    </div>
    <service-modal
      :is-show-modal="isModalVisible"
      :service="currentService"
      @close="changeModal(false)"
    />
  </div>
</template>

<script lang="ts" setup>
  import { onMounted, ref } from 'vue';
  import { useRoute } from 'vue-router';
  import AppHeader from '@/components/AppHeader.vue';
  import {
    HotelNavbar,
    ServiceList,
    ServiceModal,
    ServiceSkeleton,
  } from '@/pages';
  import { Category, IService } from '@/models';
  import { useHotelStore } from '@/stores/hotel';

  const isModalVisible = ref(false);
  const currentService = ref<IService | null>(null);
  const categoryDetails = ref<Category>({} as Category);
  const route = useRoute();
  const slug = route.params.slugCategory as string;
  const store = useHotelStore();
  const isLoading = ref(true);
  const isAnimation = ref(false);

  function changeModal(value: boolean): void {
    isModalVisible.value = value;
  }

  function openBookModal(service: IService): void {
    currentService.value = service;
    changeModal(true);
  }

  function changeAnimation(value: boolean) {
    isAnimation.value = value;
  }

  onMounted(async () => {
    window.scrollTo(0, 0);
    categoryDetails.value = await store.getCategoryDetails(slug).then(data => {
      isLoading.value = false;
      return data;
    });
  });
</script>

<style lang="scss" scoped>
  .services-page {
    flex-direction: column;

    min-height: calc(var(--height, 1vh) * 100 - 200);
    margin-top: 200px;
    margin-bottom: 87px;

    &__image {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
    }

    &__block {
      position: relative;
      left: -20px;

      display: flex;
      flex-basis: 0;
      flex-direction: column;
      flex-grow: 1;
      flex-shrink: 1;

      width: calc(100% + 40px);
      padding: 0 10px;

      border-top-left-radius: 25px;
      border-top-right-radius: 25px;
      background: white;
    }

    &__logo {
      position: absolute;
      top: -30px;
      left: 50%;

      overflow: hidden;

      transform: translateX(-50%);

      border: 5px white solid;
      border-radius: 50%;

      @include setSquareSize(70px);

      img {
        @include setSquareSize(100%);
        object-fit: cover;
      }
    }
    &__title {
      margin: 54px 0 20px;

      text-align: center;

      font: {
        weight: 600;
        @include toRem(size, 23px);
      }

      @include toRem(line-height, 28px);
    }

    &__text_gray {
      margin-top: 5px;

      color: var(--gray-black);
    }

    &__navbar {
      top: 125px;
      left: -20px !important;

      width: 100% !important;
      margin-left: 20px;
    }
  }
</style>
