<template>
  <div class="cart-modal__wrapper">
    <transition name="modal" mode="out-in" :duration="animationDuration">
      <app-modal
        v-if="isShowModal"
        class="cart-modal"
        is-rounded
        @close="onCloseModal"
      >
        <div class="cart-modal__image">
          <div class="cart-modal__circle-1"></div>
          <div class="cart-modal__circle-2"></div>
          <div class="cart-modal__icon"></div>
        </div>
        <div class="cart-modal__title">
          Войдите в приложение для оформления заказа!
        </div>
        <div class="cart-modal__description">
          Чтобы сделать заказ, пожалуйста, авторизуйтесь в приложении. Это
          поможет нам обеспечить безопасность ваших данных и упростить процесс
          покупки. Спасибо за понимание!
        </div>
        <app-button
          class="cart-modal__book"
          theme="active"
          text="Войти"
          @click="onGoToAuth"
        >
          <img alt="" src="@/assets/icons/SVG/arrow-circle-broken-left.svg" />
        </app-button>
      </app-modal>
    </transition>
  </div>
</template>

<script lang="ts" setup>
  import { animationDuration } from '@/config';
  import router from '@/router';
  import { useOrderStore } from '@/stores/cart';
  import { onBeforeRouteUpdate, useRoute } from 'vue-router';

  defineProps<{
    isShowModal: boolean;
  }>();

  const emits = defineEmits<{
    close: [];
  }>();

  const route = useRoute();
  const orderStore = useOrderStore();

  function onGoToAuth() {
    onCloseModal();
    router.push({
      name: 'WelcomePage',
      query: { hotel: route.params.slug },
    });
    orderStore.setOrderNext(true);
  }

  function onCloseModal() {
    const scrollY = document.body.style.top;
    document.body.classList.remove('modal_status_default');
    document.body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
    emits('close');
  }
</script>

<style lang="scss" scoped>
  .cart-modal {
    z-index: 1002;
    &__circle-1 {
      position: absolute;
      bottom: 0;
      left: 0;

      opacity: 0.16;
      border-radius: 50%;
      background: var(--blue-main);

      @include setSquareSize(75px);
    }
    &__circle-2 {
      position: absolute;
      top: 8px;
      right: 0;

      opacity: 0.22;
      border-radius: 50%;
      background: var(--blue-main);

      @include setSquareSize(40px);
    }
    &__icon {
      position: absolute;
      z-index: 3;
      bottom: 0;
      left: 25px;

      background: center / contain no-repeat
        url('@/assets/icons/SVG/arrow-circle-broken-left.svg');

      rotate: -30deg;
      @include setSquareSize(60px);
    }
    &__image {
      position: relative;

      width: 100px;
      height: 75px;
      margin: 50px 0 30px;
    }
    &__title {
      margin-bottom: 15px;

      text-align: center;

      font: {
        weight: 600;
        @include toRem(size, 20px);
      }

      @include toRem(line-height, 24.3px);
    }
    &__description {
      width: 100%;
      margin-bottom: 120px;

      text-align: center;

      color: var(--gray-black);

      @include toRem(line-height, 19.5px);
    }
    &__book {
      position: fixed;
      z-index: 100;
      bottom: 35px;

      width: calc(100vw - 40px);

      border: none;

      img {
        @include filter-white;
      }
    }
  }
</style>
