<template>
  <div :class="['header', { header_transparent: routerLink }]">
    <app-button
      v-if="loginVisible"
      class="header__login"
      text="Войти"
      theme="common"
      @click="onLogin"
    >
      <img
        alt=""
        src="@/assets/icons/SVG/arrow-circle-broken-left.svg"
        class="button__icon"
      />
    </app-button>
    <div v-else class="header__back">
      <div v-if="isAuth && !isSearchBarVisible" class="header__notification" />
      <app-button
        class="header__button_back"
        theme="gray"
        is-square
        @click="onClickOnLeftButton"
      >
        <img alt="" :src="require(`@/assets/icons/SVG/${icon}.svg`)" />
      </app-button>
    </div>
    <app-search-bar
      class="header__button_notification"
      :is-input-visible="isSearchBarVisible"
      @click="makeSearchBarVisible"
      @update="searchCategoryNodes"
    />
    <transition name="page" mode="out-in" :duration="animationDuration">
      <search-results
        v-if="isSearchBarVisible"
        :categories="categoryNodes"
        @book="bookService"
        @close="goToCategory"
      />
    </transition>
    <div v-if="!isSearchBarVisible" class="header__cart">
      <div v-if="count" class="header__cart_count">{{ count }}</div>
      <app-button
        class="header__button_cart"
        theme="active"
        is-square
        @click="onMoveToCart"
      >
        <img alt="" src="@/assets/icons/SVG/shopping-cart.svg" />
      </app-button>
    </div>
  </div>
</template>

<script lang="ts">
  export default {
    name: 'AppHeader',
  };
</script>

<script lang="ts" setup>
  import { computed, onMounted, ref } from 'vue';
  import { useRoute } from 'vue-router';
  import { useOrderStore } from '@/stores/cart';
  import { useAuthStore } from '@/stores/auth';
  import router from '@/router';
  import AppButton from '@/components/atoms/AppButton.vue';
  import { Cart, Category, IService } from '@/models';
  import { useHotelStore } from '@/stores/hotel';
  import { animationDuration } from '@/config';
  import { nextTick } from '@/helper';

  const props = defineProps<{
    routerLink?: string;
  }>();

  const emits = defineEmits<{
    book: [service: IService];
  }>();

  const route = useRoute();
  const cartStore = useOrderStore();
  const authStore = useAuthStore();
  const hotelStore = useHotelStore();
  const isAuth = computed(() => authStore.isAuth);
  const cart = ref<Cart>();
  const count = computed(() => cart.value?.items.length);
  const loginVisible = computed(
    () => !isAuth.value && !props.routerLink && !isSearchBarVisible.value,
  );
  const isSearchBarVisible = ref(false);
  const icon = computed(() =>
    props.routerLink || isSearchBarVisible.value ? 'arrow' : 'menu',
  );
  const categoryNodes = ref<Category[]>([]);
  const currentSearchQuery = ref('');
  const searchQuery = ref('');

  function onLogin() {
    router.push({
      name: 'WelcomePage',
      query: { hotel: route.params.slug },
    });
  }

  function onClickOnLeftButton() {
    if (isSearchBarVisible.value) makeSearchBarInvisible();
    else if (props.routerLink) onReturnBack();
    else onMoveToMenu();
  }

  function onMoveToCart() {
    router.push({
      name: 'CartPage',
    });
  }

  function bookService(service: IService) {
    emits('book', service);
  }

  function makeSearchBarVisible() {
    isSearchBarVisible.value = true;
  }

  function makeSearchBarInvisible() {
    currentSearchQuery.value = '';
    searchQuery.value = '';
    categoryNodes.value = [];
    isSearchBarVisible.value = false;
  }

  function goToCategory(category: Category) {
    if (category.parentCategoryId) {
      router.push({
        name: 'ServicesPage',
        params: { slugCategory: category.slug },
      });
    } else {
      makeSearchBarInvisible();
      nextTick(() => {
        const elem = document.querySelector(`#${category.slug}`);
        if (elem) {
          const targetPosition =
            elem.getBoundingClientRect().top + window.pageYOffset - 130;
          window.scrollTo({ top: targetPosition, behavior: 'smooth' });
        }
      });
    }
  }

  function onMoveToMenu() {
    router.push({
      name: 'MenuPage',
      query: { hotel: route.params.slug },
    });
  }

  function onReturnBack() {
    if (props.routerLink) {
      router.push({
        name: props.routerLink,
      });
    }
  }

  async function searchCategoryNodes(query: string) {
    currentSearchQuery.value = query;
  }

  onMounted(() => {
    cart.value = cartStore.getCart();
    setInterval(async () => {
      if (
        currentSearchQuery.value.length > 1 &&
        currentSearchQuery.value !== searchQuery.value
      ) {
        searchQuery.value = currentSearchQuery.value;
        categoryNodes.value = await hotelStore.searchCategoryNodes(
          currentSearchQuery.value,
          route.params.slug as string,
        );
      }
    }, 1000);
  });
</script>

<style lang="scss" scoped>
  .header {
    position: fixed;
    z-index: 1002;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    width: 100%;
    min-height: 75px;
    padding: 0 20px;

    transition: all 0.2 ease;

    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
    background: white;

    &__login {
      width: 127px;
      margin-top: 6px;
    }

    &__back {
      position: relative;
      z-index: 1002;

      margin-right: 15px;
    }

    &_transparent {
      background: transparent;
    }

    &__button_notification {
      z-index: 1002;

      margin-left: auto;
    }

    &__cart {
      position: relative;
      z-index: 1002;

      margin-left: 15px;
    }

    &__notification {
      position: absolute;
      z-index: 1002;
      top: 2px;
      right: 2px;

      border-radius: 50%;
      background: #e75252;

      @include setSquareSize(10px);
    }

    &__cart_count {
      position: absolute;
      z-index: 1002;
      top: -5px;
      right: -5px;

      display: flex;
      align-items: center;
      justify-content: center;

      color: white;
      border: white 2px solid;
      border-radius: 50%;
      background: #e75252;

      font-weight: 600;

      @include toRem(font-size, 14px);
      @include setSquareSize(24px);
    }
  }
</style>
