<template>
  <div :id="id" class="category-cards section">
    <div v-if="title" class="category-cards__title">{{ title }}</div>
    <div
      v-for="(card, index) in cards.services"
      :key="`card${index}`"
      class="card card_large"
      @click="bookService(card)"
    >
      <img class="image" :src="card.imageUrl" alt="" />
      <div class="info">
        <img class="logo" src="@/assets/images/palace.png" alt="" />
        <div>
          <div class="title">{{ card.translations.ru_RU.name }}</div>
          <div class="subtitle">{{ card.translations.ru_RU.description }}</div>
        </div>
        <div v-if="card.price" class="price">
          <div class="price__icon">₽</div>
          <div class="price__value">{{ card.price }} руб</div>
        </div>
      </div>
    </div>
    <div
      v-for="(card, index) in cards.subcategories"
      :key="`card${index}`"
      :class="['card', cardClass(index)]"
      @click="openSubcategories(card.slug)"
    >
      <img class="image" :src="card.logoKey" alt="" />
      <div :class="['label', labelClass(index)]">
        {{ card.translations.ru_RU.name }}
      </div>
      <div class="label label_count">3 шт.</div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { Category, IService } from '@/models';

  const props = defineProps<{
    title?: string;
    id: string;
    cards: Category;
  }>();

  const emits = defineEmits<{
    open: [slug: string];
    book: [service: IService];
  }>();

  const largeCardPositions = [3, 8, 11, 16, 19, 24];

  function isLargeCard(index: number) {
    const position = index + 1;
    if (largeCardPositions.includes(position)) {
      return true;
    }
    const isLastCard = props.cards.subcategories
      ? index === props.cards.subcategories.length - 1
      : 0;
    const isNextCardLarge =
      largeCardPositions.includes(position + 1) ||
      largeCardPositions.includes(position + 3);
    const isNextCardMissing = isLastCard && !isNextCardLarge;
    if (isNextCardMissing) {
      return true;
    }
    return false;
  }

  function cardClass(index: number) {
    return {
      card_large: isLargeCard(index),
      card_small: !isLargeCard(index),
    };
  }

  function labelClass(index: number) {
    const beigeIndex = (index + 1) % 8 === 1;
    const blueIndex = (index + 1) % 8 === 5;
    return {
      label_beige: beigeIndex,
      label_blue: blueIndex,
    };
  }

  function bookService(service: IService) {
    emits('book', service);
  }

  async function openSubcategories(slug: string) {
    emits('open', slug);
  }
</script>

<style lang="scss" scoped>
  .category-cards {
    position: relative;

    display: flex;
    flex-wrap: wrap;

    padding: 15px 0;

    gap: 5px;

    &__title {
      width: 100%;
      margin-bottom: 15px;

      font: {
        weight: 600;
        @include toRem(size, 23px);
      }

      @include toRem(line-height, 28px);
    }
  }
  .card {
    position: relative;

    overflow: hidden;

    width: 100%;

    border-radius: 25px;

    .image {
      width: 100%;
      height: 100%;

      object-fit: cover;
    }

    &_large {
      height: 250px;

      .label {
        width: 80%;
      }
    }
    &_small {
      width: calc(50% - 2.5px);
      height: 160px;

      .label:not(.label_count) {
        min-width: 125px;
      }
    }
  }
  .label {
    position: absolute;
    z-index: 100;
    bottom: 0;
    left: 0;

    display: flex;
    align-items: center;

    width: 86%;
    height: 50px;
    padding: 6px 12px;

    color: white;
    border-top-right-radius: 25px;
    background: #00000061;

    font-weight: 600;

    backdrop-filter: blur(3px);

    &_count {
      z-index: 100;
      top: 0;
      right: 0;
      bottom: auto;
      left: auto;

      width: 70px !important;
      height: 37px;
      padding: 5px 14px;

      border-top-left-radius: 0;
      border-bottom-left-radius: 25px;
      background: #fefefe70;
    }

    &_beige {
      background: #b08d6d;
    }
    &_blue {
      background: var(--blue-main);
    }
  }

  .info {
    position: absolute;
    z-index: 100;
    top: 10px;
    left: 10px;

    display: flex;
    flex-direction: column;

    width: 50%;
    min-height: 70%;
    padding: 10px;

    border-radius: 25px;
    background: white;
  }

  .logo {
    margin-bottom: 10px;

    border-radius: 50%;

    @include setSquareSize(60px);
    object-fit: cover;
  }

  .title {
    font-weight: 600;
    padding-bottom: 5px;

    @include toRem(line-height, 19.49px);
  }

  .subtitle {
    color: var(--gray-black);
    padding-bottom: 5px;

    @include toRem(font-size, 14px);
  }

  .price {
    display: flex;
    align-items: center;

    margin-top: auto;

    font-family: 'Montserrat', 'Proxima Nova', sans-serif;

    gap: 10px;

    &__icon {
      text-align: center;

      color: white;
      border-radius: 50%;
      background: var(--blue-main);

      font-size: 16px;
      line-height: 23px;

      @include setSquareSize(23px);
    }

    &__value {
      color: var(--gray-black);

      @include toRem(font-size, 18px);
      @include toRem(line-height, 24px);
    }
  }

  @media screen and (min-width: 420px) {
    .card_small {
      height: 180px;
    }
    .label {
      height: 60px;

      &_count {
        width: 80px !important;
        height: 50px;
      }
    }

    .card_small {
      .label:not(.label_count) {
        min-width: 170px !important;
      }
    }
  }

  @media screen and (min-width: 360px) {
    .card_small {
      .label:not(.label_count) {
        min-width: 145px;
      }
    }
  }
</style>
